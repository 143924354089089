import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import SubmitButton from '../components/SubmitButton';
import TechExpenseNotesFileEntry from './TechExpenseNotesFileEntry';

const TimesheetExpenseCp = ({ expense, payperiod, onSuccess }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [expenseLog, setExpenseLog] = useState(expense);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bodyData, setBodyData] = useState({
    status: 'submit_expense',
    current_date: '',
    data: {}
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setExpenseLog((prev) => ({
      ...prev,
      pay_period: payperiod,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF, PNG, and JPEG are allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  const updateBodyData = useCallback(() => {
    setBodyData((prev) => ({
      ...prev,
      data: { ...expenseLog }
    }));
  }, [expenseLog]);

  useEffect(() => {
    updateBodyData();
  }, [expenseLog, updateBodyData]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.error("Please upload a valid file.", { position: 'top-center' });
      return;
    }

    const formData = new FormData();
    formData.append('status', 'submit_expense');
    formData.append('expense_amount', expenseLog.expense_amount);
    formData.append('expense_comment', expenseLog.expense_comment);
    formData.append('pay_period', expenseLog.pay_period);
    if (expense?.id) {
      formData.append('id', expenseLog.id);
    }
    formData.append('file', file);

    try {
      const response = await axios.post(`${apiUrl}/tech_exp_file_crud.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log(response.data, file);
      
      if (response.data.success) {
        toast.info('Saved!', { position: 'top-center' });
        setTimeout(() => {
          onSuccess(response.data.expense); // Call the onSuccess callback
        }, 1000);
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
      }
    }
  };

  return (
    <>
      <br />
      <HeadingCp label={"Expense entry"} />
      <>
        <form
          onSubmit={handleFormSubmit}
          className='px-6 bg-white border rounded-lg py-8 mt-4'>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3 ">
                  <label htmlFor="expense_amount" className="block text-sm font-medium leading-6 text-gray-900">
                    Expense amount  <span className='text-red-500'>*</span> 
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="expense_amount"
                      id="expense_amount"
                      value={expenseLog.expense_amount || ''} 
                      onChange={handleChange}
                      required={true}
                      className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <TechExpenseNotesFileEntry handleChange={handleChange} handleFileChange={handleFileChange} timesheetLog={expenseLog} />
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SubmitButton disabled={''}>Submit</SubmitButton>
          </div>
          <br/>
          <br/>
        </form>
        
      </>
    </>
  );
};

export default TimesheetExpenseCp;
