import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CurrencyFormat from '../components/CurrencyFormat';
import { ArrowPathRoundedSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import SearchBarCP from '../components/SearchBarCP';
import { CloseButton, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindowTechTimesheetLogs from '../components/ModalWindowTechTimesheetLogs';
import ModalVendorSection from '../components/ModalVendorSection';
import Truncate from '../components/Truncate';
import { useUserData } from '../context/UserData';
import { dateToUnixTimestamp } from '../utils/dateToUnixTimestamp';
import ModalTechTimesheet from '../components/ModalTechTimesheet';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import TimesheetJobCp from '../components/TimesheetJobCp';
import TimesheetLogCp from '../components/TimesheetLogCp';
import TimesheetSaiCp from '../components/TimesheetSaiCp';
import TimesheetSSCp from '../components/TimesheetSSCp';
import TimesheetScCp from '../components/TimesheetScCp';
import TimesheetOFCp from '../components/TimesheetOFCp';
import TimesheetOFFCp from '../components/TimesheetOFFCp';
import { UtilConvertTimeStampToDate } from '../utils/UtilConvertTimeStampToDate';

const TechWkTimesheetLog = () => {
  const navigate = useNavigate();
  const {selectedDate, monthWeek} = useParams();
  const [timesheet, setTimesheet] = useState({
    t_id: '',
    employee_id: '',
    comment: '',
    expense: '',
    expense_approval: '',
    overtime_approval: '',
    mod_by: '',
    pay_period: ''
  });
  const [timesheetLog, setTimesheetLog] = useState({
    id: '',
    tech_id: '',
    date: '',
    time_type_input: '',
    numberid: '',
    address: '',
    contractor: '',
    job_status: '',
    over_hrs: '',
    reg_hrs: '',
    time_in: '',
    time_out: '',
    notes: '',
    pay_period: '',
    billable: '',
    additional_tech: ''
  });
  const [showApproved, setShowApproved] = useState(true);
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit',
    user_id: null,
    data: {}
  });
  const [holidays, setHolidays] = useState([]);
  const [timeLog, setTimeLog] = useState([]);
  const [isHoliday, setIsholiday] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState('');
  const [payperiod, setPayPeriod] = useState(null);
  const [week, setWeek] = useState([]);
  const [nextPayPeriod, setNextPayPeriod] = useState('');
  const userData = useUserData();
  const [bodyData, setBodyData] = useState({
    status: 'submit_days',
    current_date: '',
    data: {}
  });
  const [timeType, setTimeType] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;

  const handleWeek = (weekType) => {
    if (weekType === 'week1') {
      setWeek(payperiod?.week1 || []);
    } else {
      setWeek(payperiod?.week2 || []);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setDate(UtilConvertDateTodayDate(UtilConvertTimeStampToDate(selectedDate)));
    } else {
      navigate('/t/twkt');
    }
  }, [selectedDate, navigate]);

  useEffect(() => {
    fetchData(week);
  }, [week]);

  const fetchData = async (week) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/tech_crud.php?timesheet=${week}&&monthweek=${monthWeek}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      
      const timesheet_logs = Array.isArray(response.data.timesheet) ? response.data.timesheet : [];
      // if (timesheet_logs.length === 0) {
      //   toast.error("No days found!", {
      //     position: "top-center"
      //   });
      // }
      setBodyData(prev => ({ ...prev, current_date: week }));
      setIsholiday(dateToUnixTimestamp(response.data.holiday) === week);
      setPayPeriod(response.data.pay_period);
      setNextPayPeriod(response.data.pay_period.payPeriod);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        // navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (value, callback) => {
    setTimeType(value);
    if (callback) {
      callback();
    }
  };

  const resetSelection = () => {
    setTimeType('');
  };

  const getAccounts = () => {
    // Implement your getAccounts function here
  };

  const addNS = () => {
    // Implement your addNS function here
  };

  const addOFF = () => {
    // Implement your addOFF function here
  };

  return (
    <>
      <div className='mb-6'>
        For Date: {date ?? ''}
        {payperiod ? (
          <>
            <br />
            <br />
            <NavLink to={'/t/twkt'}>
              <span className="rounded-md bg-blue-500 px-2.5 py-2
                               text-sm font-semibold text-white shadow-sm 
                               hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                               focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                View Weeks
              </span>
            </NavLink>
          </>
        ) : (
          <div>loading...</div>
        )}
      </div>

      {timeType === '' ? (
        <div className="overflow-hidden">
          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="Job"
              checked={timeType === 'Job'}
              onChange={() => handleRadioChange('Job')}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            Job Number
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="Log"
              checked={timeType === 'Log'}
              onChange={() => handleRadioChange('Log')}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            Service Ticket #
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="sai"
              checked={timeType === 'sai'}
              onChange={() => handleRadioChange('sai', getAccounts)}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            Semi-Annual Inspections
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="SS"
              checked={timeType === 'SS'}
              onChange={() => handleRadioChange('SS')}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            Site Survey
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="NC"
              checked={timeType === 'SC'}
              onChange={() => handleRadioChange('SC')}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            Training
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="OF"
              checked={timeType === 'OF'}
              onChange={() => handleRadioChange('OF')}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
             Head Quarters
          </label>

          <label className="container_radio flex items-center mb-2">
            <input
              type="radio"
              name="timeType"
              value="OFF"
              checked={timeType === 'OFF'}
              onChange={() => handleRadioChange('OFF', addOFF)}
              className="mr-2 h-6 w-6"
            />
            <span className="checkmark mr-2"></span>
            OFF Day/Night
          </label>
        </div>
      ) : (
        <button
          onClick={resetSelection}
          className="mt-1 mb-4 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
        >
          Reset Selection
        </button>
      )}

      {timeType === 'Job' && <TimesheetJobCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'Log' && <TimesheetLogCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'sai' && <TimesheetSaiCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'SS' && <TimesheetSSCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'SC' && <TimesheetScCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'OF' && <TimesheetOFCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {timeType === 'OFF' && <TimesheetOFFCp date={selectedDate || ''} payperiod={nextPayPeriod || ''} />}
      {/* Add other conditions for other components here */}
    </>
  );
}

export default TechWkTimesheetLog;
