import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HeadingCp from './HeadingCp';
import SubmitButton from './SubmitButton';
import FormVarH from './FormVarH';
import TechTimeEntryCp from './TechTimeEntryCp';
import axios from 'axios';

const TimesheetLogCp = ({ date, payperiod }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;
  const navigate = useNavigate();
  const [timesheetLog, setTimesheetLog] = useState({
    id: '',
    tech_id: '',
    date: '',
    time_type_input: 'sai',
    numberid: 'Semi-Annual',
    address: '',
    contractor: '',
    job_status: '',
    over_hrs: '',
    reg_hrs: '',
    time_in: '',
    time_out: '',
    notes: '',
    pay_period: '',
    billable: false, // Added field
    additional_tech: '', // Added field
  });
  const [bodyData, setBodyData] = useState({
    status: 'submit_time',
    current_date: '',
    data: {},
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setTimesheetLog((prev) => ({
      ...prev,
      pay_period: payperiod,
      date: date,
      numberid: 'Semi-Annual',
      [name]: type === 'checkbox' ? checked : value, // Update checkbox and text inputs
    }));
  };

  const updateBodyData = useCallback(() => {
    setBodyData((prev) => ({
      ...prev,
      current_date: date,
      data: { ...timesheetLog },
    }));
  }, [date, timesheetLog]);

  useEffect(() => {
    updateBodyData();
  }, [timesheetLog, updateBodyData]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data[0] === 'success') {
        toast.info('Saved!', {
          position: 'top-center',
        });
        setTimeout(() => {
          navigate('/t/twkt');
        }, 1000); // Delay navigation for 1 second
      } else {
        toast.error(response.data, { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', { position: 'top-center' });

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  return (
    <>
      <br />
      <HeadingCp label={'Semi-Annual entry'} />
      <form onSubmit={handleFormSubmit} className="px-6 bg-white border rounded-lg py-8 mt-4">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* Billable Checkbox */}
              <div className="sm:col-span-4">
                <label className="flex items-center text-sm font-medium leading-6 text-gray-900">
                  <input
                    type="checkbox"
                    name="billable"
                    checked={timesheetLog.billable}
                    onChange={handleChange}
                    className="mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  Billable
                </label>
              </div>

              {/* Job Site Field */}
              <FormVarH
                label={'Job Site'}
                inside={'address'}
                val={timesheetLog.address}
                onChange={handleChange}
                required
              />

              {/* Additional Tech Field */}
              <FormVarH
                label={'Additional Tech'}
                inside={'additional_tech'}
                val={timesheetLog.additional_tech || ''}
                onChange={handleChange}
                required={false}
              />

              {/* Time Entry Fields */}
              <TechTimeEntryCp handleChange={handleChange} timesheetLog={timesheetLog} />
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <SubmitButton disabled={''}>Submit</SubmitButton>
        </div>
        <br />
        <br />
      </form>
    </>
  );
};

export default TimesheetLogCp;
