import React, { useState } from 'react';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';

const ModalTechTimesheet = ({ timesheet_logs, hrs, payperiod, tech_name, onSave }) => {
  const [logs, setLogs] = useState(timesheet_logs); // Local state for the timesheet logs
  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleInputChange = (index, field, value) => {
    const updatedLogs = logs.map((log, i) =>
      i === index ? { ...log, [field]: value } : log
    );
    setLogs(updatedLogs);
  };

  const handleSave = (index) => {
    onSave(logs[index]); // Pass the updated log to the parent component
    setEditIndex(null); // Exit edit mode after saving
  };

  const toggleEditMode = (index) => {
    setEditIndex(index === editIndex ? null : index); // Toggle edit mode for the selected line item
  };

  return (
    <div className="mx-auto w-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-500 mb-8">{tech_name}</h1>

      <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg" style={{ padding: '2px' }}>
        <thead>
          <tr className="divide-x divide-gray-400">
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
              Billable | Date
            </th>
            <th className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Type | Add Tech
            </th>
            <th className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
              Time in
            </th>
            <th className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
              Time out
            </th>
            <th className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell max-w-[50px]">
              Reg hrs
            </th>
            <th className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell max-w-[50px]">
              OT hrs
            </th>
            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Customer
            </th>
            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {logs.map((fetchData, index) => {
            const backgroundClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-200';

            return (
              <tr key={fetchData.id} className={`${backgroundClass} divide-x divide-gray-400`}>
                {/* Billable and Date */}
                <td className="py-2 pl-4 pr-3 text-sm max-w-[80]">
                  {editIndex === index ? (
                    <>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={fetchData.billable === 1}
                          onChange={(e) =>
                            handleInputChange(index, 'billable', e.target.checked ? 1 : 0)
                          }
                          className="mr-2"
                        />
                        Billable
                      </label>
                      <input
                        type="date"
                        value={fetchData.date}
                        onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                        className="block mt-2 border border-gray-300 rounded max-w-[100]"
                      />
                    </>
                  ) : (
                    <>
                      <span className={fetchData.billable === 1 ? 'text-green-600' : 'text-orange-600'}>
                        {fetchData.billable === 1 ? 'Yes' : 'No'}
                      </span>
                      <br />
                      <span>{UtilConvertDateTodayDate(fetchData.date)}</span>
                    </>
                  )}
                </td>

                {/* Type and Additional Tech */}
                <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell">
                  {editIndex === index ? (
                    <>
                      <input
                        type="text"
                        value={fetchData.numberid}
                        onChange={(e) => handleInputChange(index, 'numberid', e.target.value)}
                        className="block mb-2 border border-gray-300 rounded"
                      />
                      <input
                        type="text"
                        value={fetchData.additional_tech || ''}
                        onChange={(e) => handleInputChange(index, 'additional_tech', e.target.value)}
                        className="block border border-gray-300 rounded"
                      />
                    </>
                  ) : (
                    <>
                      <span>{fetchData.numberid}</span>
                      <br />
                      <p className="text-xs">{fetchData.additional_tech || 'N/A'}</p>
                    </>
                  )}
                </td>

                {/* Time In */}
                <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[120px]">
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={fetchData.time_in}
                      onChange={(e) => handleInputChange(index, 'time_in', e.target.value)}
                      className="block border border-gray-300 rounded max-w-[90px]"
                    />
                  ) : (
                    fetchData.time_in
                  )}
                </td>

                {/* Time Out */}
                <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[120px]">
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={fetchData.time_out}
                      onChange={(e) => handleInputChange(index, 'time_out', e.target.value)}
                      className="block border border-gray-300 rounded max-w-[90px]"
                    />
                  ) : (
                    fetchData.time_out
                  )}
                </td>

                {/* Reg Hrs */}
                <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[80px]">
                  {editIndex === index ? (
                    <input
                      type="number"
                      value={fetchData.reg_hrs}
                      onChange={(e) => handleInputChange(index, 'reg_hrs', e.target.value)}
                      className="block border border-gray-300 rounded max-w-[60px]"
                    />
                  ) : (
                    `${fetchData.reg_hrs} hrs`
                  )}
                </td>

                {/* OT Hrs */}
                <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[80px]">
                  {editIndex === index ? (
                    <input
                      type="number"
                      value={fetchData.over_hrs}
                      onChange={(e) => handleInputChange(index, 'over_hrs', e.target.value)}
                      className="block border border-gray-300 rounded max-w-[60px]"
                    />
                  ) : (
                    `${fetchData.over_hrs} hrs`
                  )}
                </td>

                {/* Customer */}
                <td className="px-3 py-2 text-sm text-gray-500">
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={fetchData.address}
                      onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                      className="block border border-gray-300 rounded"
                    />
                  ) : (
                    <>
                      <span>{fetchData.address}</span>
                      <br />
                      <p className="text-xs">{fetchData.contractor}</p>
                    </>
                  )}
                </td>

                {/* Actions */}
                <td className="px-3 py-2 text-sm text-gray-500">
                  {editIndex === index ? (
                    <>
                      <button
                        onClick={() => handleSave(index)}
                        className="text-green-500 hover:text-green-700 mr-2"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => toggleEditMode(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => toggleEditMode(index)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ModalTechTimesheet;
