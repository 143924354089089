import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

function useSubmitPost() {
    const navigate = useNavigate();

    const handleSubmit = async (event, { bodyData, api, errorLabel, redirectPath }) => {
            event.preventDefault(); // Prevent the default form submit behavior
        try {
            
            const response = await axios.post(api, bodyData, {
                headers: { 'Content-Type': 'application/json' }
            });
            toast.info('Saved!', {
                position: 'top-center'
            });
            
            navigate(redirectPath); // Use redirectPath to navigate after submission
        } catch (error) {
            if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", {
            position: "top-center"
            });
            navigate('/login');
            } else if (error.response && error.response.status === 403) {
            // setAccess(true);
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
            });
            }
            // const errorMsg = `Error submitting ${errorLabel}`;
            // if (error.response) {
            //     console.error(`${errorMsg}`, error.response.data);
            // } else if (error.request) {
            //     console.error(`${errorMsg}`, error.request);
            // } else {
            //     console.error('Error', error.message);
            // }
        }
    };

    return handleSubmit;
}

export default useSubmitPost;