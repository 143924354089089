import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import InspectionDetailsModal from '../components/InspectionDetailsModal';
import InspectionFormModal from '../components/InspectionFormModal';
import CalendarHeader from '../components/CalendarHeader';
import { formatTimeTo12HourUtil } from '../utils/formatTimeTo12HourUtil';
import DayViewTimeline from '../components/DayViewTimeline';

const InspectionCalendarView = () => {
  const [inspections, setInspections] = useState([]);
  const [viewMode, setViewMode] = useState('week'); // 'day', 'week', 'month'
  const [loading, setLoading] = useState(false);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [formData, setFormData] = useState({
    maintenance_id: '',
    job_site_name: '',
    lead_tech_id: '',
    scheduled_due_date: '',
    schedule_date_to: '',
    actual_inspection_date: '',
    status: '',
  });
  const [isEditing, setIsEditing] = useState(false);

  const [technicians, setTechnicians] = useState([]);

  // For Month/Week
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); 
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDates, setSelectedDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // For Day View
  const [selectedDay, setSelectedDay] = useState(new Date()); // default to "today"

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // ---------------- Date Helpers ----------------
  const getWeekStart = (date = new Date()) => {
    const dayOfWeek = date.getDay() || 7; 
    const diff = date.getDate() - dayOfWeek + 1; 
    return new Date(date.getFullYear(), date.getMonth(), diff);
  };

  const getWeekDates = (startOfWeek) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(
        startOfWeek.getFullYear(),
        startOfWeek.getMonth(),
        startOfWeek.getDate() + i
      );
      dates.push(currentDay);
    }
    return dates;
  };

  const getMonthDates = (year, month) => {
    const date = new Date(year, month - 1, 1);
    const dates = [];
    while (date.getMonth() + 1 === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  // ---------------- useEffect: Update Calendar Range ----------------
  useEffect(() => {
    if (viewMode === 'week') {
      const today = new Date();
      const weekStart = getWeekStart(today);
      const weekDates = getWeekDates(weekStart);
      setSelectedDates(weekDates);
      setStartDate(weekDates[0]);
      setEndDate(weekDates[6]);
    } else if (viewMode === 'month') {
      const monthDates = getMonthDates(selectedYear, selectedMonth);
      setSelectedDates(monthDates);
      setStartDate(monthDates[0]);
      setEndDate(monthDates[monthDates.length - 1]);
    }
    // If Day View, we rely on selectedDay in a separate effect
    fetchTechnicians();
  }, [viewMode, selectedMonth, selectedYear, isModalOpen, isDetailModalOpen]);

  // For week/month, fetch once startDate/endDate are set
  useEffect(() => {
    if ((viewMode === 'week' || viewMode === 'month') && startDate && endDate) {
      fetchInspections(startDate, endDate);
    }
  }, [viewMode, startDate, endDate]);

  // For Day View, fetch data for just that day
  useEffect(() => {
    if (viewMode === 'day') {
      const dayStart = new Date(selectedDay);
      dayStart.setHours(0, 0, 0, 0);

      const dayEnd = new Date(selectedDay);
      dayEnd.setHours(23, 59, 59, 999);

      fetchInspections(dayStart, dayEnd);
    }
  }, [viewMode, selectedDay]);

  // ---------------- API Calls ----------------
  const fetchInspections = async (startD, endD) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php`, {
        params: {
          getInspections: 'all',
          startDate: startD.toISOString().split('T')[0],
          endDate: endD.toISOString().split('T')[0],
        },
      });
      const data = Array.isArray(response.data) ? response.data : [];
      const filteredData = data.filter(
        (inspection) => (inspection.status || '').toLowerCase() !== 'completed'
      );
      setInspections(filteredData);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTechnicians = async () => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php`, {
        params: { employee_list: 'true' },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });
      const employeesData = Array.isArray(response.data) ? response.data : [];
      if (employeesData.length === 0) {
        toast.error('No maintenance technicians found!', { position: 'top-center' });
      }
      setTechnicians(employeesData);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      toast.error('Session expired. Please login again.', {
        position: 'top-center',
      });
      navigate('/login');
    } else if (error.response && error.response.status === 403) {
      toast.error('Access denied.', {
        position: 'top-center',
      });
    } else {
      toast.error('An error occurred while fetching data.', {
        position: 'top-center',
      });
    }
  };

  // ---------------- Navigation / Handlers ----------------
  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split('-');
    setSelectedYear(parseInt(year, 10));
    setSelectedMonth(parseInt(month, 10));
  };

  const handlePreviousWeek = () => {
    const prevWeekStart = new Date(selectedDates[0]);
    prevWeekStart.setDate(prevWeekStart.getDate() - 7);
    const weekDates = getWeekDates(prevWeekStart);
    setSelectedDates(weekDates);
    setStartDate(weekDates[0]);
    setEndDate(weekDates[6]);
  };

  const handleNextWeek = () => {
    const nextWeekStart = new Date(selectedDates[0]);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);
    const weekDates = getWeekDates(nextWeekStart);
    setSelectedDates(weekDates);
    setStartDate(weekDates[0]);
    setEndDate(weekDates[6]);
  };

  const handleViewChange = (e) => {
    setViewMode(e.target.value);
  };

  const openModal = (isEdit = false) => {
    setIsModalOpen(true);
    setIsEditing(isEdit);
    if (!isEdit) {
      setFormData({
        maintenance_id: '',
        job_site_name: '',
        lead_tech_id: '',
        scheduled_due_date: '',
        schedule_date_to: '',
        actual_inspection_date: '',
        status: '',
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // ---------------- Form Submit / Delete ----------------
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, {
        data: formData,
        status: 'recordInspection',
      });
      if (response.data.success) {
        toast.success('Inspection saved successfully!');
        closeModal();
        reFetchCurrentView();
      } else {
        toast.error(response.data.error || 'Failed to save inspection.');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, {
        data: { id: formData?.id },
        status: 'deleteInspection',
      });
      if (response.data.success) {
        toast.success('Inspection successfully deleted!');
        closeModal();
        reFetchCurrentView();
      } else {
        toast.error(response.data.error || 'Failed to save inspection.');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  // Utility to re-fetch data based on current view mode
  const reFetchCurrentView = () => {
    if (viewMode === 'day') {
      const dayStart = new Date(selectedDay);
      dayStart.setHours(0, 0, 0, 0);
      const dayEnd = new Date(selectedDay);
      dayEnd.setHours(23, 59, 59, 999);
      fetchInspections(dayStart, dayEnd);
    } else if (viewMode === 'week' || viewMode === 'month') {
      if (startDate && endDate) {
        fetchInspections(startDate, endDate);
      }
    }
  };

  // ---------------- Click Handlers ----------------
  const handleEventClick = (inspection) => {
    setSelectedInspection(inspection);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const handleEditInspection = () => {
    const editableInspection = {
      ...selectedInspection,
      id: selectedInspection.inspection_id, // rename for the form
    };
    setFormData(editableInspection);
    setIsDetailModalOpen(false);
    setIsModalOpen(true);
    setIsEditing(true);
  };

  // For week/month: cell click => new inspection
  const handleCellClick = (date, technician) => {
    openModal(false);
    setFormData((prevData) => ({
      ...prevData,
      scheduled_due_date: date.toISOString().split('T')[0],
      schedule_date_to: date.toISOString().split('T')[0],
      lead_tech_id: technician?.id || '',
    }));
  };

  // For day view, user picks a day from a date input
  const handleDayChange = (e) => {
    const [year, month, day] = e.target.value.split('-').map(Number);

    // Create a date in local time, but at noon (12:00) so you don't slip to previous day
    const newDay = new Date(year, month - 1, day, 12, 0, 0);

    setSelectedDay(newDay);
  };

  // ---------------- Render ----------------
  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <HeadingCp label="Inspection Calendar View" />

      {/* Top Control Row */}
      <div className="flex justify-between items-start mb-3">
        {/* View Mode Selector */}
        <div className="flex items-center space-x-2">
          <label htmlFor="view-mode">View:</label>
          <select
            id="view-mode"
            className="rounded-md border border-gray-300 py-1 px-2"
            value={viewMode}
            onChange={handleViewChange}
          >
            <option value="day">Day View</option>
            <option value="week">Week View</option>
            <option value="month">Month View</option>
          </select>
        </div>

        {/* If Day View => show date picker for the day */}
        {viewMode === 'day' && (
          <div className="flex items-center space-x-2">
            <label htmlFor="day-selector">Select Day:</label>
            <input
              type="date"
              id="day-selector"
              value={selectedDay.toISOString().split('T')[0]}
              onChange={handleDayChange}
              className="rounded-md border border-gray-300 py-1 px-2"
            />
          </div>
        )}
        
        {/* (Optional) Month Selector if you want for Month View */}
        {/* 
        {viewMode === 'month' && (
          <div className="flex items-center">
            <label htmlFor="month-selector" className="mr-2">
              Select Month:
            </label>
            <input
              type="month"
              id="month-selector"
              value={`${selectedYear}-${String(selectedMonth).padStart(2, '0')}`}
              onChange={handleMonthChange}
              className="rounded-md border border-gray-300 py-1 px-2"
            />
          </div>
        )}
        */}
      </div>

      {/* Week Navigation */}
      {viewMode === 'week' && (
        <CalendarHeader
          viewMode={viewMode}
          selectedWeek={selectedDates}
          handlePreviousWeek={handlePreviousWeek}
          handleNextWeek={handleNextWeek}
        />
      )}

      {/* Buttons */}
      <div className="mb-4 space-x-4">
        <button
          type="button"
          onClick={() => openModal(false)}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
            shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          New Inspection +
        </button>

        <button
          type="button"
          onClick={() => navigate(`/maintenance`)}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
            shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Contracts
        </button>
      </div>

      {/* Conditional Render of Different Views */}
      {viewMode === 'day' && (
        <DayViewTimeline
          selectedDay={selectedDay}
          inspections={inspections}
          onEventClick={(insp) => {
            // open details for that inspection
            setSelectedInspection(insp);
            setIsDetailModalOpen(true);
          }}
          onEmptyClick={({ hour, minute }) => {
            // create new inspection
            openModal(false);
            const dayStr = selectedDay.toISOString().split('T')[0];
            // format hour/minute => "HH:MM:SS"
            const hh = String(hour).padStart(2, '0');
            const mm = String(minute).padStart(2, '0');

            setFormData((prev) => ({
              ...prev,
              scheduled_due_date: dayStr,
              schedule_date_to: dayStr,
              schedule_time_in: `${hh}:${mm}:00`,
              schedule_time_out: `${hh === '23' ? hh : String(hour+1).padStart(2, '0')}:00:00`,
            }));
          }}
        />
      )}

      {(viewMode === 'week' || viewMode === 'month') && (
        <WeekOrMonthView
          viewMode={viewMode}
          selectedDates={selectedDates}
          technicians={technicians}
          inspections={inspections}
          handleEventClick={handleEventClick}
          handleCellClick={handleCellClick}
        />
      )}

      {/* Modals */}
      <InspectionFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleFormSubmit}
        technicians={technicians}
        apiUrl={apiUrl}
        handleDelete={handleDelete}
        isEditing={isEditing}
      />

      <InspectionDetailsModal
        isOpen={isDetailModalOpen}
        onClose={closeDetailModal}
        inspection={selectedInspection}
        onEdit={handleEditInspection}
      />
    </div>
  );
};

// ------------------------- DayView Component -------------------------
// const DayView = ({ selectedDay, inspections, onEventClick, onCellClick }) => {
//   // We'll define hours [0..23].
//   const hours = Array.from({ length: 24 }, (_, i) => i);

//   // Convert 0..23 to "12:00 AM", "1:00 AM", ..., "11:00 PM"
//   const formatHour12 = (hour) => {
//     const suffix = hour < 12 ? 'AM' : 'PM';
//     let displayHour = hour % 12;
//     if (displayHour === 0) displayHour = 12;
//     return `${displayHour}:00 ${suffix}`;
//   };

//   // For each hour, see all inspections that overlap that hour
//   const getInspectionsForHour = (hour) => {
//     return inspections.filter((insp) => {
//       const [inH] = (insp.schedule_time_in || '00:00:00').split(':').map(Number);
//       const [outH] = (insp.schedule_time_out || '23:59:59').split(':').map(Number);
//       // If hour is between inH and outH (inclusive), we consider it an overlap.
//       return hour >= inH && hour <= outH;
//     });
//   };

//   // Format the day for the header
//   const dayLabel = selectedDay.toLocaleDateString('en-US', {
//     weekday: 'long',
//     month: 'short',
//     day: 'numeric',
//   });

//   return (
//     <div className="overflow-auto">
//       {/* 2 columns: left=hours, right=the selected day */}
//       <div className="grid" style={{ gridTemplateColumns: '120px 1fr' }}>
//         {/* Top row */}
//         <div className="bg-gray-100 sticky top-0 z-10 p-2 border">{/* Empty left corner */}</div>
//         <div className="bg-gray-100 sticky top-0 z-10 text-center p-2 border">
//           {dayLabel}
//         </div>

//         {/* Hour Rows */}
//         {hours.map((hour) => {
//           const inspThisHour = getInspectionsForHour(hour);

//           // If no inspections for this hour, user can click to create one
//           if (inspThisHour.length === 0) {
//             return (
//               <React.Fragment key={hour}>
//                 {/* Left column: hour label */}
//                 <div className="border p-2 bg-white sticky left-0 z-10">
//                   {formatHour12(hour)}
//                 </div>
                
//                 {/* Right column: empty cell => new insp */}
//                 <div
//                   className="border h-16 cursor-pointer hover:bg-slate-100 bg-white"
//                   onClick={() => onCellClick(hour)}
//                 >
//                   {/* nothing shown if empty */}
//                 </div>
//               </React.Fragment>
//             );
//           }

//           // If there are multiple inspections, display them side by side
//           return (
//             <React.Fragment key={hour}>
//               {/* Left column: hour label */}
//               <div className="border p-2 bg-white sticky left-0 z-10">
//                 {formatHour12(hour)}
//               </div>

//               {/* Right column: show multiple inspections side-by-side */}
//               <div className="border h-16 cursor-pointer bg-blue-50">
//                 <div className="flex flex-row flex-wrap gap-2 p-1 h-full">
//                   {inspThisHour.map((insp, i) => (
//                     <div
//                       key={insp.id || i}
//                       className="flex-1 min-w-[120px] bg-blue-200 hover:bg-blue-300 p-1 rounded shadow cursor-pointer"
//                       onClick={() => onEventClick(insp)}
//                     >
//                       <p className="font-bold text-xs text-center">
//                         {insp.job_site_address || 'Unnamed'}
//                       </p>
//                       <p className="text-green-600 text-xs text-center">
//                         {insp.customer_name || 'No Customer'}
//                       </p>
//                       <p className="text-gray-600 text-xs text-left">
//                         {insp.schedule_time_in || 'no time'} 
//                         {insp.schedule_time_out
//                           ? ' - ' + insp.schedule_time_out
//                           : ' - no time'}
//                       </p>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </React.Fragment>
//           );
//         })}
//       </div>
//     </div>
//   );
// };


// -------------------- WeekOrMonthView Component --------------------
const WeekOrMonthView = ({
  viewMode,
  selectedDates,
  technicians,
  inspections,
  handleEventClick,
  handleCellClick,
}) => {
  // parseLocalDate helper
  const parseLocalDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day, 0, 0, 0);
  };

  // For each date/technician, find any inspection
  const getScheduledInspections = (date, technicianId) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);

    return inspections.filter((insp) => {
      const fromDate = parseLocalDate(insp.scheduled_due_date);
      const toDate = parseLocalDate(insp.schedule_date_to || insp.scheduled_due_date);

      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);

      const leadId = String(insp.lead_tech_id);
      const addlId = String(insp.additional_tech_id || '');

      const techMatch = leadId === technicianId || addlId === technicianId;
      const dateInRange = currentDate >= fromDate && currentDate <= toDate;

      return techMatch && dateInRange;
    });
  };

  return (
    <div className="overflow-auto">
      <div
        className="grid"
        style={{ gridTemplateColumns: `200px repeat(${selectedDates.length}, 1fr)`}}
      >
        {/* blank top-left corner */}
        <div className="bg-gray-100 sticky top-0 z-10" />

        {/* Date headers */}
        {selectedDates.map((date, idx) => (
          <div key={idx} className="bg-gray-100 text-center p-2 sticky top-0 z-10 border">
            {date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' })}
          </div>
        ))}

        {/* Technician rows */}
        {technicians.map((tech, rowIndex) => (
          <React.Fragment key={tech.id || rowIndex}>
            <div className="border p-2 bg-white sticky left-0 z-10">
              <div className="font-bold text-black">
                {tech.first_name} {tech.last_name}
                <div className="text-xs text-green-700">{tech.department}</div>
              </div>
            </div>

            {selectedDates.map((date, colIndex) => {
              const techId = String(tech.id);
              const scheduledInspections = getScheduledInspections(date, techId);
              const isScheduled = scheduledInspections.length > 0;

              return (
                <div
                  key={`${tech.id || rowIndex}-${colIndex}`}
                  className={`border h-16 cursor-pointer hover:bg-slate-100 ${
                    isScheduled ? 'bg-blue-200' : 'bg-white'
                  }`}
                  onClick={() =>
                    isScheduled
                      ? handleEventClick(scheduledInspections[0])
                      : handleCellClick(date, tech)
                  }
                >
                  {isScheduled && (
                    <>
                      <p className="font-bold text-xs text-center">
                        {scheduledInspections[0].job_site_address || 'Unnamed'}
                      </p>
                      <p className="text-green-600 text-xs text-center">
                        {scheduledInspections[0].customer_name || 'customer missing'}
                      </p>
                      <p className="text-gray-600 text-xs text-left pl-1">
                        {scheduledInspections[0].schedule_time_in
                          ? formatTimeTo12HourUtil(scheduledInspections[0].schedule_time_in)
                          : 'no time'}
                        {scheduledInspections[0].schedule_time_out
                          ? ' - ' + formatTimeTo12HourUtil(scheduledInspections[0].schedule_time_out)
                          : ' - no time'}
                      </p>
                    </>
                  )}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InspectionCalendarView;
