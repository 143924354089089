import React, { useState, useEffect, useCallback } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, NavLink } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import SubmitButton from '../components/SubmitButton';
import FormVarH from '../components/FormVarH';
import TechTimeEntryCp from './TechTimeEntryCp';
import { UtilConvertTimeStampToDate } from '../utils/UtilConvertTimeStampToDate';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';

const TimesheetJobCp = ({ date, payperiod }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;
  const navigate = useNavigate();
  const [timesheetLog, setTimesheetLog] = useState({
    id: '',
    tech_id: '',
    date: '',
    time_type_input: '',
    numberid: '',
    address: '',
    contractor: '',
    job_status: '',
    over_hrs: '',
    reg_hrs: '',
    time_in: '',
    time_out: '',
    notes: '',
    pay_period: '',
    billable: false, // Billable field added
    additional_tech: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobSelect, setJobSelect] = useState('');
  const [bodyData, setBodyData] = useState({
    status: 'submit_time',
    current_date: '',
    data: {},
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setTimesheetLog((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleWeek = (job) => {
    setTimesheetLog({
      address: job.job_site,
      contractor: job.customer_name,
      date: date,
      numberid: job.job_number,
      time_type_input: 'Job',
      id: 'New',
      over_hrs: '',
      notes: '',
      pay_period: payperiod,
      billable: false, // Initialize billable as unchecked
      additional_tech: job.additional_tech || '',
    });
    setJobSelect(job);
    if (job) {
      setJobs([]);
      setSearchTerm('');
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);

    const bodyData = {
      status: 'search_job',
      data: { search: searchTerm },
    };

    try {
      const response = await axios.post(`${apiUrl}/tech_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data && Array.isArray(response.data)) {
        setJobs(response.data);
        setJobSelect('');
      } else {
        setJobs([]);
        toast.error('not found ' + searchTerm, {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', {
        position: 'top-center',
      });

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error(
          "Oops!! you don't have access to this area. Speak to the CFO.",
          {
            position: 'top-center',
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setJobs(jobs);
    } else {
      const filtered = jobs.filter((job) =>
        (job.customer_name &&
          job.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (job.job_number &&
          job.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (job.job_site &&
          job.job_site.toLowerCase().includes(value.toLowerCase()))
      );
      setJobs(filtered);
    }
  };

  const updateBodyData = useCallback(() => {
    setBodyData((prev) => ({
      ...prev,
      current_date: date,
      data: { ...timesheetLog },
    }));
  }, [date, timesheetLog]);

  useEffect(() => {
    updateBodyData();
  }, [timesheetLog, updateBodyData]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      console.log(bodyData);
      console.log(response.data);

      if (response.data[0] === 'success') {
        toast.info('Saved!', {
          position: 'top-center',
        });
        setTimeout(() => {
          navigate('/t/twkt');
        }, 2000); // Delay navigation for 2 seconds
      } else {
        toast.error(response.data, {
          position: 'top-center',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error(
          "Oops!! you don't have access to this area. Speak to the CFO.",
          {
            position: 'top-center',
          }
        );
      }
    }
  };

  return (
    <>
      <br />
      <HeadingCp label={'Proposal# lookup'} />
      <ul role="list" className="divide-y divide-gray-100">
        <div className="relative rounded-md shadow-sm flex-grow">
          <input
            type="text"
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder="Search for Proposal # | Job Site"
          />
          <button
            onClick={handleSearchClick}
            disabled={!searchTerm}
            className={`absolute right-0 top-0 bottom-0 text-white px-3 py-2 rounded-r-md ${
              !searchTerm ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'
            }`}
          >
            Search
          </button>
        </div>
        {jobs.map((job) => (
          <li
            key={job.id}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="min-w-0 divide-y-2">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {job.job_site}
                </p>
                <p className="mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                  {job.f_p_type}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">{job.customer_name}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="whitespace-nowrap">{job.job_number}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Project Manager: {job.pm}</p>
              </div>
            </div>
            <div
              onClick={() => handleWeek(job)}
              className="flex flex-none items-center gap-x-4 text-blue-800"
            >
              <ChevronRightIcon className="h-5 hover:cursor-pointer" />
            </div>
          </li>
        ))}
      </ul>

      {jobSelect && (
        <>
          <form
            onSubmit={handleFormSubmit}
            className="px-6 bg-white border rounded-lg py-8 mt-4"
          >
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h6 className="text-base font-semibold leading-7 text-gray-600">
                  <span className="text-blue-500 text-sm">
                    Proposal#: {jobSelect.job_number}
                  </span>
                  <br />
                  <span className="text-gray-500 text-sm">
                    {UtilConvertDateTodayDate(
                      UtilConvertTimeStampToDate(date)
                    )}
                  </span>
                </h6>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Billable Checkbox */}
                  <div className="sm:col-span-4">
                    <label className="flex items-center text-sm font-medium leading-6 text-gray-900">
                      <input
                        type="checkbox"
                        name="billable"
                        checked={timesheetLog.billable}
                        onChange={handleChange}
                        className="mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      Billable
                    </label>
                  </div>

                  {/* Job Site Field */}
                  <FormVarH
                    label={'Job Site'}
                    inside={'address'}
                    val={timesheetLog.address || ''}
                    onChange={handleChange}
                    required
                  />

                  <FormVarH
                    label={'Customer'}
                    inside={'contractor'}
                    val={timesheetLog.contractor || ''}
                    onChange={handleChange}
                    required
                  />

                  <FormVarH
                    label={'Additional Tech'}
                    inside={'additional_tech'}
                    val={timesheetLog.additional_tech || ''}
                    onChange={handleChange}
                    required={false}
                  />

                  <TechTimeEntryCp
                    handleChange={handleChange}
                    timesheetLog={timesheetLog}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <SubmitButton disabled={''}>Submit</SubmitButton>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default TimesheetJobCp;
